import React from 'react';
import {
  Box,
  Flex,
  VStack,
  HStack,
  Heading,
  Link,
  Text,
  Image,
} from '@chakra-ui/react';
import ScrollToTop from './ScrollToTop';

const Footer = () => {
  return (
    <Box bg="primaryTurquoise.400" color="white">
      {/* Bottom Footer Section */}
      <Box bg="primaryOrange.400" py={2}></Box>

      <Flex
        mx="auto"
        px={5}
        justify={{ base: 'center', md: 'space-between' }}
        alignItems="center"
        color="white"
      >
        {/* Links Sections */}
        <Flex
          w="100%"
          justify={{ base: 'space-evenly', md: 'space-evenly' }}
          flexWrap="wrap"
          px={10}
          py={5}
          fontFamily={'Arimo'}
          direction={{ base: 'column', md: 'row' }}
        >
          {/* Destinations Section */}
          <VStack
            align={{ base: 'center', md: 'flex-start' }}
            spacing={1}
            mb={{ base: 4, md: 0 }} // Marges pour espacement sur mobile
          >
            <Link href="/destinations">
              <Heading fontFamily={'Raleway'} fontSize="md" mb={2}>
                DESTINATIONS
              </Heading>
            </Link>
          </VStack>

          {/* Products Section */}
          <VStack
            align={{ base: 'center', md: 'flex-start' }}
            spacing={1}
            mb={{ base: 4, md: 0 }}
          >
            <Link href="/nos-produits">
              <Heading fontFamily={'Raleway'} fontSize="md" mb={2}>
                NOS PRODUITS
              </Heading>
            </Link>
            <Link href="/nos-produits/type/birdie" fontSize="sm">
              Packages "BIRDIE"
            </Link>
            <Link href="/nos-produits/type/eagle" fontSize="sm">
              Packages "EAGLE"
            </Link>
            <Link href="/nos-produits/type/albatros" fontSize="sm">
              Packages "ALBATROS"
            </Link>
            <Link href="/nos-produits/type/allinone" fontSize="sm">
              ALL IN ONE
            </Link>
            <Link href="/nos-produits/type/coups-de-coeur" fontSize="sm">
              Coups de cœur
            </Link>
            <Link href="/nos-produits/type/pro" fontSize="sm">
              Stages de Pro
            </Link>
          </VStack>

          {/* Offers Section */}
          <VStack
            align={{ base: 'center', md: 'flex-start' }}
            spacing={2}
            mb={{ base: 4, md: 0 }}
          >
            <Link href="/communications/offres">
              <Heading fontFamily={'Raleway'} fontSize="md" mb={2}>
                NOS OFFRES
              </Heading>
            </Link>
          </VStack>

          {/* Communication Section */}
          <VStack
            align={{ base: 'center', md: 'flex-start' }}
            spacing={2}
            mb={{ base: 4, md: 0 }}
          >
            <Link href="/communications">
              <Heading fontFamily={'Raleway'} fontSize="md" mb={2}>
                COMMUNICATION
              </Heading>
            </Link>
            <Link href="/communications/blog" fontSize="sm">
              Blog
            </Link>
            <Link href="/communications/offres" fontSize="sm">
              Nos offres
            </Link>
            <Link href="/communications/carnet-de-voyage" fontSize="sm">
              Carnet de voyage
            </Link>
            <Link href="/newsletter" fontSize="sm">
              S'inscrire à notre newsletter
            </Link>
          </VStack>

          {/* Agency Section */}
          <VStack
            align={{ base: 'center', md: 'flex-start' }}
            spacing={2}
            mb={{ base: 4, md: 0 }}
          >
            <Link href="/agence">
              <Heading fontFamily={'Raleway'} fontSize="md" mb={2}>
                L'AGENCE
              </Heading>
            </Link>
            <Link href="/agence" fontSize="sm">
              À propos de nous
            </Link>
            <Link href="/agence" fontSize="sm">
              Notre engagement
            </Link>
          </VStack>

          {/* Contact Section */}
          <VStack
            align={{ base: 'center', md: 'flex-start' }}
            spacing={2}
            mb={{ base: 4, md: 0 }}
          >
            <Link href="/contact">
              <Heading fontFamily={'Raleway'} fontSize="md" mb={2}>
                CONTACT
              </Heading>
            </Link>

            {/* Phone Link */}
            <Link href="tel:+41797164198" fontSize="sm">
              +41 79 716 41 98
            </Link>

            {/* Email Link */}
            <Link href="mailto:info@travelgolfclub.ch" fontSize="sm">
              info@travelgolfclub.ch
            </Link>
          </VStack>
        </Flex>
      </Flex>

      <ScrollToTop />

      {/* Footer Text at Bottom Right */}
      <HStack
        justifyContent={{ base: 'center', md: 'flex-end' }} // Centré sur mobile
        mr={{ base: 0, md: 30 }}
        pb={3}
      >
        <Text
          bottom={1}
          right={4}
          fontFamily={'Arimo'}
          fontSize="xs"
          color="white"
          textAlign={{ base: 'center', md: 'right' }}
        >
          @WebDesign Salmane Hajouji - @Brandesigner Talented Loolye
        </Text>
      </HStack>

      <HStack
        justifyContent={{ base: 'center', md: 'space-evenly' }} // Centré sur mobile
        alignItems="center"
        py={4} // Padding vertical pour donner de l'espace
        bgColor={'white'}
        flexDirection={{ base: 'column', md: 'row' }} // Empile verticalement sur mobile
      >
        {/* Logo 1 */}
        <Image
          src="https://firebasestorage.googleapis.com/v0/b/travelgolfclub.appspot.com/o/Public%2FAffiliations%2FIAGTO-MemOf-OneLine-GolfTourOperator-JPG.jpg?alt=media&token=cdf20063-afc3-4aa2-be55-726337d49669"
          alt="IAGTO"
          width="150px"
          objectFit="contain"
          mb={{ base: 2, md: 0 }} // Espace en bas sur mobile
        />

        {/* Logo 2 */}
        <Image
          src="https://firebasestorage.googleapis.com/v0/b/travelgolfclub.appspot.com/o/Public%2FAffiliations%2Flogo_FR_FondGarantieTPA%20(1).jpg?alt=media&token=b504474a-9498-4f91-b67c-311e8a966575"
          alt="Fond garantie"
          width="250px"
          objectFit="contain"
          mb={{ base: 2, md: 0 }}
        />

        {/* Logo 3 */}
        <Image
          src="https://firebasestorage.googleapis.com/v0/b/travelgolfclub.appspot.com/o/Public%2FAffiliations%2Ftps_logo_272.png?alt=media&token=e80091c8-460b-40b0-aeb6-f5be7c8f31cb"
          alt="TPS"
          width="200px"
          objectFit="contain"
          mb={{ base: 2, md: 0 }}
        />
      </HStack>

      {/* Legal Links */}
      <HStack
        justify={{ base: 'center', md: 'space-evenly' }} // Centré sur mobile
        color="#4a2e2f"
        fontSize={{ base: 'sm', md: 'lg' }} // Taille de police adaptée
        backgroundColor={'white'}
        fontFamily={'Arimo'}
        letterSpacing="5px"
        flexWrap="wrap"
        textAlign="center"
        flexDirection={{ base: 'column', md: 'row' }}
      >
        <Link href="/mentions-legales" mb={{ base: 2, md: 0 }}>
          Mentions légales
        </Link>
        <Link
          target="_blank"
          href="/protection-des-donnees"
          mb={{ base: 2, md: 0 }}
        >
          Protection des données
        </Link>
        <Link href="/cookies" mb={{ base: 2, md: 0 }}>
          Politique de cookies
        </Link>
        <Link href="/cgv" mb={{ base: 2, md: 0 }}>
          CGV
        </Link>
        <Link href="/assurances" mb={{ base: 2, md: 0 }}>
          Assurances
        </Link>
      </HStack>
    </Box>
  );
};

export default Footer;
