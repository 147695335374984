import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebase';
import { Container, Spinner } from '@chakra-ui/react';

const PrivateRoute = ({ children }) => {
  const [user, loading] = useAuthState(auth);

  if (loading) {
    return (
      <Container
        height="100vh" // Full viewport height
        display="flex" // Flexbox to center content
        justifyContent="center" // Horizontal center
        alignItems="center" // Vertical center
      >
        <Spinner color="red" />
      </Container>
    ); // Show loading spinner or text while checking auth
  }

  return user ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
