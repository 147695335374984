import { Text } from '@chakra-ui/react';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom'; // Import React Router's Link

const HeaderLink = ({ to, mr, label, onClick = null }) => {
  return (
    <RouterLink to={to} onClick={onClick} style={{ marginRight: mr }}>
      <Text fontSize="xl" fontFamily={'Raleway'}>
        {label}
      </Text>
    </RouterLink>
  );
};

export default HeaderLink;
