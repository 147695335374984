// AnalyticsTracker.jsx
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { logEvent, setAnalyticsCollectionEnabled } from 'firebase/analytics';
import { analytics } from '../../firebase';
import { Box, Button, Text, Link } from '@chakra-ui/react';

// Fonction utilitaire pour donner un nom compréhensible aux pages
const getPageTitleFromPath = pathname => {
  const segments = pathname.split('/').filter(Boolean);

  if (segments.length === 0) return 'Accueil';

  if (segments[0] === 'agence') return 'Agence';
  if (segments[0] === 'communications') {
    if (segments[1] === 'offres') {
      if (segments[2]) {
        // Détail d'une offre
        return '';
      } else {
        return 'Offres spéciales';
      }
    }
    if (segments[1] === 'blog') return 'Blog Communications';
    if (segments[1] === 'carnet-de-voyage') return 'Carnet de Voyages';
    return 'Communications';
  }

  if (segments[0] === 'nos-produits') {
    if (segments[1] === 'type') {
      if (segments[2] === 'allinone') return 'Nos Produits: All In One';
      if (segments[2] === 'coups-de-coeur')
        return 'Nos Produits: Coups de Coeur';
      if (segments[2]) return `Nos Produits: Type ${segments[2]}`;
      return 'Nos Produits: Types';
    }
    if (segments[1] === 'details' && segments[2]) {
      return '';
    }
    return 'Nos Produits';
  }

  if (segments[0] === 'assurances') return 'Assurances';
  if (segments[0] === 'destinations') return 'Destinations';
  if (segments[0] === 'login') return 'Login';
  if (segments[0] === 'newsletter') return 'Newsletter';
  if (segments[0] === 'contact') return 'Contact';
  if (segments[0] === 'cookies') return 'Cookies';
  if (segments[0] === 'cgv') return 'Conditions Générales de Vente';
  if (segments[0] === 'mentions-legales') return 'Mentions Légales';
  if (segments[0] === 'protection-des-donnees') return 'Protection des Données';
  if (segments[0] === 'administration') return 'Administration';

  // Par défaut, on retourne le chemin
  return pathname;
};

const AnalyticsTracker = () => {
  const location = useLocation();
  const [hasConsented, setHasConsented] = useState(null);
  const [consentValue, setConsentValue] = useState(() =>
    localStorage.getItem('analytics_consent')
  );

  useEffect(() => {
    const consent = localStorage.getItem('analytics_consent');
    if (consent === 'true') {
      setHasConsented(true);
      setAnalyticsCollectionEnabled(analytics, true);
    } else if (consent === 'false') {
      setHasConsented(false);
      setAnalyticsCollectionEnabled(analytics, false);
    } else {
      setHasConsented(false);
    }

    // On met à jour consentValue
    setConsentValue(consent);
  }, []);
  useEffect(() => {
    // Track la page si l'utilisateur a consenti
    if (hasConsented) {
      const pageTitle = getPageTitleFromPath(location.pathname);

      if (pageTitle) {
        logEvent(analytics, 'page_view', {
          page_title: pageTitle,
          page_location: window.location.href,
          page_path: location.pathname,
        });
      }
    }
  }, [location, hasConsented]);

  const handleAccept = () => {
    localStorage.setItem('analytics_consent', 'true');
    setAnalyticsCollectionEnabled(analytics, true);
    setHasConsented(true);
    setConsentValue('true');
    console.log('accepted');
  };

  const handleReject = () => {
    localStorage.setItem('analytics_consent', 'false');
    setAnalyticsCollectionEnabled(analytics, false);
    setHasConsented(false);
    setConsentValue('false');
    console.log('refused');
  };

  return hasConsented == false &&
    localStorage.getItem('analytics_consent') === null ? (
    <Box
      position="fixed"
      bottom="0"
      left="0"
      right="0"
      bg="white"
      borderTop="1px solid #ccc"
      p={4}
      zIndex={1000}
      display="flex"
      flexDirection={{ base: 'column', md: 'row' }}
      alignItems={{ base: 'flex-start', md: 'center' }}
      justifyContent="space-between"
    >
      <Text
        fontFamily="Raleway"
        fontSize={{ base: 'sm', md: 'md' }}
        mb={{ base: 4, md: 0 }}
        mr={{ base: 0, md: 4 }}
        textAlign={'justify'}
      >
        Nous utilisons des cookies afin de mesurer la fréquentation et
        l’utilisation de nos contenus de manière entièrement anonymisée, dans le
        but d'améliorer votre expérience. Aucune donnée permettant de vous
        identifier personnellement n’est enregistrée. Vous pouvez en savoir plus
        sur la manière dont nous utilisons les cookies et sur vos droits dans
        notre{' '}
        <Link href="/cookies" color="teal.500">
          Politique en matière de cookies
        </Link>
        . Acceptez-vous l'utilisation de ces cookies d'analyse anonymes ?
      </Text>

      <Box
        display="flex"
        flexDirection="row"
        flexWrap={{ base: 'wrap', md: 'nowrap' }}
        mt={{ base: 2, md: 0 }} // Un léger marge top sur mobile si besoin
      >
        <Button
          variant="outline"
          color="primaryOrange.400"
          border="2px solid"
          bg="#fffbf7"
          _hover={{ bg: 'primaryCreme.400' }}
          fontSize={{ base: 'md', md: 'lg' }}
          p={{ base: 4, md: 6 }}
          mr={2}
          fontFamily="Raleway"
          fontWeight="400"
          onClick={handleAccept}
          mb={{ base: 2, md: 0 }} // Espace sur mobile si les boutons se superposent
        >
          Accepter
        </Button>
        <Button
          variant="outline"
          border="2px solid"
          fontSize={{ base: 'md', md: 'lg' }}
          p={{ base: 4, md: 6 }}
          fontFamily="Raleway"
          fontWeight="400"
          onClick={handleReject}
        >
          Refuser
        </Button>
      </Box>
    </Box>
  ) : null;
};

export default AnalyticsTracker;
