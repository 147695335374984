import React, { Suspense, lazy } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import {
  ChakraProvider,
  Container,
  extendTheme,
  Spinner,
  Center,
} from '@chakra-ui/react';

// Importations de styles
import '@fontsource/raleway';
import '@fontsource/arimo';
import '@fontsource/playfair-display';

// Importations statiques pour les composants critiques
import Layout from './components/Global/Layout';
import PrivateRoute from './PrivateRoute';
import ErrorPage from './containers/views/ErrorPage';

// Définir les composants de route en lazy loading
const Accueil = lazy(() => import('./containers/Accueil'));
const Administration = lazy(() => import('./containers/Administration'));
const Agence = lazy(() => import('./containers/Agence'));
const Assurances = lazy(() => import('./containers/Assurances'));
const CGV = lazy(() => import('./containers/CGV'));
const Communications = lazy(() =>
  import('./containers/Communications/Communications')
);
const Contact = lazy(() => import('./containers/Contact'));
const Destinations = lazy(() => import('./containers/Destinations'));
const NewsletterPage = lazy(() => import('./containers/NewsletterPage'));
const Packages = lazy(() => import('./containers/Packages/PackagesPage'));
const PackageAllInOnePage = lazy(() =>
  import('./containers/Packages/PackageAllInOnePage')
);
const PackageTypePage = lazy(() =>
  import('./containers/Packages/PackageTypePage')
);
const PackageDetailsPage = lazy(() =>
  import('./containers/Packages/PackageDetailsPage')
);
const CoupsDeCoeur = lazy(() => import('./containers/Packages/CoupsDeCoeur'));
const Blog = lazy(() => import('./containers/Communications/Blog'));
const CarnetVoyages = lazy(() =>
  import('./containers/Communications/CarnetVoyages')
);
const OffresSpeciales = lazy(() =>
  import('./containers/Communications/OffresSpeciales')
);
const OffreDetailsPage = lazy(() =>
  import('./containers/Communications/OffreDetailsPage')
);
const MentionsLegales = lazy(() => import('./containers/MentionsLegales'));
const Cookies = lazy(() => import('./containers/Cookies'));
const ProtectionsDesDonnees = lazy(() =>
  import('./containers/ProtectionsDesDonnees')
);
const Login = lazy(() => import('./containers/views/Login'));

// Définir le thème Chakra UI
const theme = extendTheme({
  colors: {
    primaryOrange: {
      50: '#ffeae5',
      100: '#ffcab8',
      200: '#ff9c87',
      300: '#ff6e57',
      400: '#fd5827',
      500: '#e34f1d',
      600: '#b23d16',
      700: '#812b0f',
      800: '#4f1908',
      900: '#210700',
    },
    primaryTurquoise: {
      50: '#e5f9f8',
      100: '#c1f0ed',
      200: '#97e4e0',
      300: '#6dd9d3',
      400: '#3cbebb', // Base color
      500: '#32a9a5',
      600: '#27837e',
      700: '#1c5e58',
      800: '#103a32',
      900: '#041510',
    },
    primaryCreme: {
      50: '#fefdfb',
      100: '#fcf6e6',
      200: '#faedd1',
      300: '#f8e4bb',
      400: '#fef2e8', // Base color
      500: '#ddc3a5',
      600: '#c2a27b',
      700: '#a68252',
      800: '#89612b',
      900: '#6d4100',
    },
    primaryVert: {
      50: '#f3ffe5',
      100: '#e9ff4f',
      200: '#f7fdae',
      300: '#e0f0a8',
      400: '#c8df91',
      500: '#afce77',
      600: '#8cae5c',
      700: '#6a8d42',
      800: '#476d27',
      900: '#254d0d',
    },
  },
  styles: {
    global: () => ({
      body: {
        bg: '#fffbf7',
      },
    }),
  },
  components: {
    Badge: {
      defaultProps: {
        colorScheme: 'red', // Default color scheme
      },
    },
    Button: {
      baseStyle: {
        fontFamily: 'Raleway', // Default font family
      },
    },
  },
});

// Définir les routes avec lazy loading
const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <Layout>
        <Accueil />
      </Layout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/agence',
    element: (
      <Layout>
        <Agence />
      </Layout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/communications',
    element: (
      <Layout>
        <Communications />
      </Layout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/communications/blog',
    element: (
      <Layout>
        <Blog />
      </Layout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/communications/offres',
    element: (
      <Layout>
        <OffresSpeciales />
      </Layout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/communications/offres/:id',
    element: (
      <Layout>
        <OffreDetailsPage />
      </Layout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/communications/carnet-de-voyage',
    element: (
      <Layout>
        <CarnetVoyages />
      </Layout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/nos-produits',
    element: (
      <Layout>
        <Packages />
      </Layout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/nos-produits/type/:packageType',
    element: (
      <Layout>
        <PackageTypePage />
      </Layout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/nos-produits/details/:packageId',
    element: (
      <Layout>
        <PackageDetailsPage />
      </Layout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/nos-produits/type/allinone',
    element: (
      <Layout>
        <PackageAllInOnePage />
      </Layout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/nos-produits/type/coups-de-coeur',
    element: (
      <Layout>
        <CoupsDeCoeur />
      </Layout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/assurances',
    element: (
      <Layout>
        <Assurances />
      </Layout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/destinations',
    element: (
      <Layout>
        <Destinations />
      </Layout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/login',
    element: (
      <Layout>
        <Login />
      </Layout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/newsletter',
    element: (
      <Layout>
        <NewsletterPage />
      </Layout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/contact',
    element: (
      <Layout>
        <Contact />
      </Layout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/cookies',
    element: (
      <Layout>
        <Cookies />
      </Layout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/cgv',
    element: (
      <Layout>
        <CGV />
      </Layout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/mentions-legales',
    element: (
      <Layout>
        <MentionsLegales />
      </Layout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/protection-des-donnees',
    element: (
      <Layout>
        <ProtectionsDesDonnees />
      </Layout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/administration',
    element: (
      <PrivateRoute>
        <Layout>
          <Container maxWidth={'1500px'}>
            <Administration />
          </Container>
        </Layout>
      </PrivateRoute>
    ),
    errorElement: <ErrorPage />,
  },
]);

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Suspense
        fallback={
          <Center height="100vh">
            <Spinner size="xl" color="primaryTurquoise.500" />
          </Center>
        }
      >
        <RouterProvider router={router} />
      </Suspense>
    </ChakraProvider>
  );
}

export default App;
