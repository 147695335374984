import {
  Box,
  Flex,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { ReactComponent as Logo } from '../../assets/Logo/3lignes sans HL/SVG/Blanc et crème/Logo TGC 3L SH Blanc.svg';
import HeaderLink from './HeaderLink';

const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        height="20vh"
        maxHeight="20vh"
        color="white"
        bgGradient="linear(to-b, primaryTurquoise.400 0%, rgba(60, 190, 187, 0.8) 40%, transparent 100%)"
        position="relative"
        zIndex="10"
        mb="-20vh"
      >
        {/* Left Navigation (40% width) */}
        <Flex
          width="45%"
          justifyContent="flex-end"
          display={{ base: 'none', md: 'flex' }}
          mb={10}
          color="white"
        >
          <HeaderLink to="/" mr="30px" label="Accueil" />
          <HeaderLink to="/destinations" mr="30px" label="Destinations" />
          <HeaderLink to="/nos-produits" mr="30px" label="Nos produits" />
        </Flex>

        <Box
          width="10%"
          minWidth="189px"
          height="100%"
          display={{ base: 'none', md: 'block' }}
          textAlign="center"
          color="white"
        >
          <Logo width="100%" height="100%" />
        </Box>

        <Flex
          width="45%"
          justifyContent="flex-start"
          display={{ base: 'none', md: 'flex' }}
          mb={10}
          color="white"
          ml="30px"
        >
          <HeaderLink
            to="/communications/offres"
            mr="30px"
            label="Nos offres"
          />
          <HeaderLink to="/communications" mr="30px" label="Communications" />
          <HeaderLink to="/agence" mr="30px" label="L'agence" />
          <HeaderLink to="/contact" mr="30px" label="Contact" />
        </Flex>

        <Flex
          alignItems="center"
          justifyContent="space-between"
          width={'100%'}
          display={{ base: 'flex', md: 'none' }} // Visible only on mobile
        >
          {/* Hamburger Menu Icon */}
          <IconButton
            aria-label="Open Menu"
            icon={<HamburgerIcon />}
            variant="outline"
            color="primaryCreme.400"
            onClick={onOpen} // Open Drawer on click
            ml={10}
          />

          <Box mr={4}>
            <Logo width="100px" height="100px" />
          </Box>
        </Flex>
      </Flex>

      {/* Mobile Drawer for Navigation */}
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent bg="rgba(0, 0, 0, 0.8)">
          <DrawerCloseButton color="white" />
          <DrawerBody>
            <Flex
              flexDirection="column"
              alignItems="start"
              mt={10}
              color="white"
            >
              <HeaderLink to="/" onClick={onClose} label="Accueil" />
              <HeaderLink
                to="/destinations"
                onClick={onClose}
                label="Destinations"
              />
              <HeaderLink
                to="/nos-produits"
                onClick={onClose}
                label="Nos produits"
              />
              <HeaderLink
                to="/communications/offres"
                onClick={onClose}
                label="Nos offres"
              />
              <HeaderLink
                to="/communications"
                onClick={onClose}
                label="Communications"
              />
              <HeaderLink to="/agence" onClick={onClose} label="L'agence" />
              <HeaderLink to="/contact" onClick={onClose} label="Contact" />
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Header;
