const firebaseConfig = {
  apiKey: 'AIzaSyDyRllVlBMIpnNQM_ePzzriNZyTuinUxtU',
  authDomain: 'travelgolfclub.firebaseapp.com',
  projectId: 'travelgolfclub',
  storageBucket: 'travelgolfclub.appspot.com',
  messagingSenderId: '182010589332',
  appId: '1:182010589332:web:a1ba98b8b35a1ef6480481',
  measurementId: 'G-8Y7TJEDCHR',
};

export default firebaseConfig;
