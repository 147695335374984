import React, { useState, useEffect } from 'react';
import { Box, IconButton } from '@chakra-ui/react';
import { FaArrowUp } from 'react-icons/fa';

const ScrollToTop = () => {
  const [showButton, setShowButton] = useState(false);

  // Effect to show the button after scrolling down
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Function to scroll to the top
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Box position="fixed" bottom="50px" right="50px" zIndex="10">
      {showButton && (
        <IconButton
          icon={<FaArrowUp />}
          onClick={scrollToTop}
          colorScheme="primaryTurquoise"
          aria-label="Scroll to top"
          size="lg"
          isRound
        />
      )}
    </Box>
  );
};

export default ScrollToTop;
