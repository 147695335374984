// Layout.jsx
import React from 'react';
import { Box } from '@chakra-ui/react';
import Footer from './Footer';
import InstagramFeed from './FeedInstagram';
import { ScrollRestoration } from 'react-router-dom';
import Header from '../Utils/Header';
import AnalyticsTracker from './AnalyticsTracker';

const Layout = ({ children }) => {
  return (
    // Root container with overflowX hidden to prevent horizontal scrolling
    <Box bg="black" minHeight="100vh" overflowX="hidden">
      {/* Centered content container with maxWidth and background color */}
      <Box
        maxWidth="1800px"
        width="100%"
        mx="auto"
        bg="#fffbf7"
        display="flex"
        flexDirection="column"
        minHeight="100vh"
      >
        {/* Header affiché sur toutes les pages */}
        <Header />

        {/* Main content area */}
        <Box as="main" flex="1">
          {children}
        </Box>

        {/* Footer and Instagram Feed */}
        <Box>
          <InstagramFeed />
          <Footer />
        </Box>
      </Box>

      <ScrollRestoration />
      <AnalyticsTracker />
    </Box>
  );
};

export default Layout;
