// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage'; // Importer Firebase Storage
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
} from 'firebase/auth';

import { getAnalytics } from 'firebase/analytics';

import firebaseConfig from './firebaseConfig';

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication
export const auth = getAuth(app);

// Initialize Firestore
export const db = getFirestore(app);

// Initialize Firebase Storage
export const storage = getStorage(app); // Exporter Firebase Storage

// Google sign-in provider
const provider = new GoogleAuthProvider();

// Google Sign-in
export const signInWithGoogle = () => {
  return signInWithPopup(auth, provider);
};

// Google Sign-out
export const logOut = () => {
  return signOut(auth);
};

const analytics = getAnalytics(app);

export { analytics };
